 

body {
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  background: var(--bgColor);
  color: var(--textColor);
  display: flex;
  flex-direction: column;
  margin: 0;
  transition: all 0.3s;
  min-height: 100vh;
  will-change: background-color;

}

#viewport {
  z-index: 10;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

a {
  color: var(--textColor);
  text-decoration: none;
  transition: all 0.3s;

}

a:hover {
  opacity: 0.5;
}

img {
  max-width: 100%;
}

li {
  list-style: none;
}

h1 {
  font-size: 2.4rem;
}

p {
  font-size: 1.4rem;
}

canvas {
  outline: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  min-height: 450px;
  top: 0;
  z-index: 0;
}

.overlay {
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  min-height: 450px;
  background: var(--bgColor);
  will-change: background-color;
  transition: background 0.7s;
  opacity: .8;
  display: block;
  content: "";
  position: absolute;
}

.bg-holder {
  position: absolute;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  min-height: 450px;
}

header {
  position: absolute;
  z-index: 3;
  width: 100vw;
  color: var(--textColor);
  transition: background-color 0.5s;
  overflow: unset;
  padding: 1.5rem 7rem;
}
.header-scroll {
  background: var(--bgColor);
  opacity: 0.95;
}
header div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  opacity: 0;
  top: -200px;
}

header h1 {
  font-weight: 400;
}

header ul {
  display: flex;
  filter: brightness(0.95);
  opacity: 0;
  left: 0;
  top: calc(100vh - 4.25em);
  min-width: 100vh;
  transform: rotate(-90deg) translateY(1em);
  -webkit-transform: rotate(-90deg) translateY(4rem);
  transform-origin: 0 100%;
  -webkit-transform-origin: 0 100%;
  position: absolute;
  padding-right: 12em;
  z-index: 1;
  overflow: auto;
  background: var(--bgColor);
  will-change: background-color;
  transition: background 0.7s;
}

header li:first-child {
  color: var(--textColor);
  flex-grow: 1;
}

header li {
  list-style: none;
  font-weight: 400;
  padding: 1em 1.5em;
  font-size: 1.2rem;
}

header .logo {
  width: 2.5rem;
  cursor: pointer;
  transition: all .7s;
}

header .logo-txt {
  cursor: pointer;
  transition: all .7s;
}

header .logo:hover,
header .logo-txt:hover {
  opacity: 0.8;
}

main {
  margin: 4.5rem;
  padding: 0 2.5rem;
  position: relative;
  z-index: 2;
  flex: 1;
}

footer section,
main section {
  padding: 1rem 2.5rem;
}

footer {
  padding-top: 7rem;
  position: relative;
  z-index: 2;
  margin: 7rem;
}

.about {
  width: 75%;
  height: 100vh;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

iframe{
  width:95%;
  max-width:900px;
  margin: 0 auto;
  aspect-ratio: 16/9;
  display: block;
}
.about--inside {
  width: 100%;
  height: unset;
  margin-top: 15rem;
}

.about-title--inside {
  font-size: 12rem !important;
}

.about-title {
  font-size: calc(5rem + 1vw);
  font-weight: 700;
  line-height: .88em;
  overflow: hidden;
  margin: 0 0 3rem;
  text-transform: uppercase;
}

.about-title span {
  position: relative;
  top: 250px;
  transform: rotate(15deg);
  display: inline-block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  opacity: 0;
  display: block;
  line-height: 1.1;
}

.about-title p {
  display: block;
  background: var(--textColor);
  transition: all 0.3s;
  opacity: 0;
  width: 6rem !important;
  height: .5rem;
  margin: 3rem 0 0;
  overflow: hidden;
}

section {
  min-height: 100vh;

}

.services p,
.services li,
.contact p,
.about p {
  font-size: 1.8rem;
  width: 80%;
}

.about>p {
  font-size: 1.8rem;
  width: 80%;
  position: relative;
  top: 250px;
  transform: rotate(15deg);
  display: inline-block;
  overflow: hidden;
  margin: 1rem 0;
  padding: 0;
  opacity: 0;
}

.services li,
.services p,
.services h1,
.contact p {
  margin: 0;
}

.about h1 {
  margin: 0 0 3rem;
}

.contact h1 {
  margin: 3rem 0 0 3rem;
}

.social a {
  color: var(--textColor);
  margin-right: 2rem;
  margin-top: 2rem;
  display: inline-block;
  transition: all 0.3s;
}

.social a:hover {
  color: black;
}

.scroll-content {
  overflow: hidden;
}

.scroll-down {
  position: absolute;
  display: none;
  width: 100vw;
  height: 100vh;
  min-height: 450px;
  align-items: flex-end;
  justify-content: center;
  top: 0;
  text-transform: lowercase;
  font-size: 1.5rem;
  pointer-events: none;
  z-index: 2;
  -webkit-text-stroke: .5px var(--textColor);
  -webkit-text-fill-color: tranparent;
  color: transparent;
  letter-spacing: 2px;
}

.clients {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 3rem;
}

.clients li {
  width: calc(18% - 1px);
  margin: 1px;
  text-align: left;
  background: white;
}

.mobile-grid {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3rem auto;
  overflow: hidden;
  align-items: flex-start;
}

.mobile-grid img {
  width: 20% !important;
}

#imgs img {
  width: 90%;
  display: block;
  margin: 3rem auto;
}

.studio-title {}

.clients img {
  width: 58%;
  padding: 0;
  will-change: transform;
}

li,
section,
ul,
.projects,
.projects ul,
.projects li,
.contact,
#contact,
.projects li div {
  overflow: hidden;

}

.projects li {
  display: flex;
  height: 100vh;
  max-height: 800px;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.projects li div {
  width: 48%;
}

.projects li img {
  width: 48%;

}

.projects h1 {
  font-size: 3rem;
  color: var(--textColor);
  line-height: 1.2em;
  overflow: hidden;
  font-weight: normal;
}

.projects h2 {
  font-size: 5.2rem;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin: 0 0 3rem 0;
  padding: 0;
  line-height: 1.2em;
  overflow: hidden;
}

.projects h2::after {
  content: "";
  background: var(--textColor);
  width: 2em;
  height: 3px;
  display: block;
  margin-top: .2em;
}

.projects p {
  font-size: 1.8rem;
  width: 90%;
}

h1.title {
  font-size: 6rem;
  margin: 10rem 0 1rem;
  color: var(--textColor);
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  max-width: 75%;
  font-weight: bold;
  line-height: 1em;
  transform-origin: left bottom;
  overflow: hidden;
  text-transform: uppercase;
}


.services li {
  padding: 0 0 .5rem 0;
}

.services-title li>b {
  font-size: 3.5rem;
  padding-top: 5rem;
  display: block;
}

.clients {
  display: flex;
  background: white;
  padding: 0;
  justify-content: flex-start !important;
}

.services ul {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.left-p {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start !important;
}

.about-a {
  font-size: 1.3rem;
  width: fit-content;
  text-transform: uppercase !important;
  display: block;
  line-height: 2.7em;
}

.contact-a {
  display: block;
  font-size: 4rem;
  font-weight: bold;
  width: fit-content;
}

.contact-sm {
  text-align: left;
  position: relative;
  top: -.5em;
  font-size: 3rem;
}

.transition-holder {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
  overflow: hidden;
  visibility: hidden;
}

.transition-holder div {
  width: 100%;
  height: 22%;
  background: var(--bgColor);
  will-change: transform, background-color;
  transform-origin: left;
}

.transition-holder div:nth-child(1) {
  filter: brightness(.88);
}

.transition-holder div:nth-child(2) {
  filter: brightness(.95);
}

.transition-holder div:nth-child(3) {
  filter: brightness(.93);
}

.transition-holder div:nth-child(4) {
  filter: brightness(.9);
}

.transition-holder div:nth-child(5) {
  filter: brightness(.88);
}

.transition-holder div:nth-child(6) {
  filter: brightness(.93);
}

.transition-holder div:nth-child(7) {
  filter: brightness(.90);
}


@media only screen and (max-width:1150px) {
  html {
    font-size: 70%;
  }

  .services ul {
    flex-wrap: wrap;
  }

  footer section,
  main section {
    padding: 1rem 0;
  }

  .about-title--inside {
    font-size: 7rem !important;
    line-height: unset;
  }

  main {}

  .about .br {
    display: none;
  }

  .about p {
    font-size: 2rem;
    width: 100%;
  }

  .about-title {
    font-size: 4.5rem;
    line-height: 1.2em;
    margin: 0 0 1rem;
  }

  .about {
    margin: 2rem;
    width: calc(100% - 4rem);
  }

  .about h1 {
    margin: 7rem 0 1rem;
    max-width: 100%;
  }

  .about p {
    max-width: 85%;
  }

  .about-title span {
    width: 100%;

    position: relative;
    margin: 0rem 0;
  }

  header li {
    padding: 0.5em 1em;
    font-size: 1.8rem;
  }

  header li:first-child {
    display: none;
  }

  header {
    padding: 0;
  }

  header div {
    background: var(--bgColor);

    padding: 10px 15px 5px;
  }

  canvas {
    margin-left: 0;
  }

  header h1:first-child {
    display: none;
  }

  header ul {
    filter: brightness(1);
    padding-right: 0em;
    transform: unset;
    top: 2rem;
    left: unset;
    width: 70vw;
    justify-content: space-around;
    position: absolute;
    height: fit-content;
    transform-origin: left;
    right: 2rem;
    bottom: unset;
    min-width: auto;
    background-color: unset;
  }


  .projects li {
    margin: 1rem 1rem 15rem 1rem;
    max-height: unset;
    min-height: 100vh;
    height: auto;
  }

  .projects li div {
    width: 58%;
  }

  .projects li img {
    border-radius: 0px;
    margin: 5rem 1rem;
  }

  .projects h2 {
    font-size: 4.5rem;
  }

  h1.title {
    font-size: 5rem;

  }

  section {
    margin: 2rem;
  }

  footer {
    margin: 0;
  }

  main {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width:880px) {
  html {
    font-size: 55%;
  }

  .about--inside {

    margin-top: 10rem !important;
  }

  main {
    padding: .5em 0 5em 0;
  }

  .projects li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .projects li div {
    width: 90%;
    margin: 3rem 0;
  }

  .projects li img {
    width: 60%;
    height: 55vh;
    object-fit: cover;
    margin: 1rem 0rem;
    object-position: center;
    min-height: 300px;
  }

  .projects h2::after {
    margin: 0.5em 0;
  }

  .projects p {
    width: 100%;
  }

  .projects h2 {
    font-size: 4.2rem;
    margin: 1rem 0 1rem 0;
  }

  .about {
    margin: 2rem;
  }

  .clients li {
    width: calc(33.3% - 2px) !important;
  }

  .services p,
  .services li,
  .contact p,
  .about p {
    width: 95%;
  }

}

@media only screen and (max-width:640px),
(orientation: portrait) {
  html {
    font-size: 50%;
  }


  .clients img {
    width: 100%;
    padding: 0;
  }

  .mobile-grid img {
    width: 40% !important;

  }

  .mobile-grid {
    width: 100%;

  }

  #imgs img {
    width: 100%;
  }

  .about-title span {
    width: 100%;
    margin: 0rem 0;
  }

  .about-title p {

    margin: 2rem 0;
  }

  .about-title {
    font-size: 4.8rem;
  }

  .about-title--inside {
    font-size: 7rem !important;
  }

  .about h1 {
    margin: 0 0 1rem;
    max-width: 100%;
  }

  .about p {
    font-size: 1.8rem;
    max-width: 98%;
  }

  .projects li div {
    width: 95%;
    margin: 1em auto;
  }

  .projects li div {
    width: 95%;

    margin: 1em auto;
  }

  .projects li img {
    width: 100%;
    margin: 1rem 0rem;
    height: 40vh;
  }

  h1.title {
    font-size: 4.5rem;
    margin: 3rem 0;
  }

  .projects li {
    margin: 0 0 5rem 0;
    justify-content: center;
    flex-direction: column-reverse;
    min-height: 60vh;
  }

  .projects h2 {
    font-size: 3.8rem;
    margin: 1.5rem 0 1rem 0;
  }

  .services p,
  .services h1,
  .contact p {
    margin: 0;
    width: 100%;
  }

  .clients li {
    width: calc(32% - 2px) !important;
    padding: 0;
  }

  .contact-a {
    font-size: 3.2rem;
  }

  .projects p {
    font-size: 2rem;
  }

  .services p,
  .services li,
  .contact p,
  .about p {
    font-size: 2rem;
    width: 100%;
  }
}


@media only screen and (max-width:440px) {
  h1.title {
    font-size: 3.8rem;
  }
}

@media only screen and (max-width:340px) {
  html {
    font-size: 40%;
  }
}

@media only screen and (min-width:1440px) {
  html {
    font-size: 85%;
  }

  .projects li {
    max-height: unset;
  }
}

@media only screen and (min-width:2440px) {
  html {
    font-size: 100%;
  }

}

@media (max-height:450px) and (min-aspect-ratio: 13/9) {

  .scroll-down {
    height: 105vh;
    min-height: 500px;
  }

}



.dg.ac {
  min-height: 100px !important;
  z-index: 10 !important;
}

.scrollbar-thumb {
  border-radius: 0px !important;
  background: black !important;
}